.render-image img {
    box-shadow: rgb(0 0 0 / 70%) 0px 5px 11px 2px;
    border: 4px dashed grey;
    background-color: grey;
    border-radius: 0%;
    transition: width 0.5s ease 0s;
    width: 200px;
}


@media (min-width: 1000px) {
    .render-image img {
        width: 300px;
    }
}
@media (min-width: 900px) {
    .render-image img {
        width: 250px;
    }
}