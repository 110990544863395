.main-section {
    width: 100%;
    z-index: 999;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 44%;
    flex-direction: column;
}
.upper-div{
    position: absolute;
    width: 100%;
    padding-top: 60px;
    top: 0px;
    background-image: url('./../images/test.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}
/* .video-section{
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: -111;
} */
#myVideo {
    position: absolute;
    top: -70px;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
  }

.placeholder {
    width: 32px;
    height: 32px;
}

@media (max-width: 967px) {
    .upper-div{
        background-image: url('./../images/test-mob.png');
        background-position: center;
    }
    .main-section {
        top: 35%;
    }
}
