@font-face {
    font-family: 'Futura Bold';
    src: url('./fonts/futura/FuturaBoldfont.ttf');
}
@font-face {
    font-family: 'Futura Medium';
    src: url('./fonts/futura/futuraMediumbt.ttf');
}

@font-face {
    font-family: 'Basquiat Regular';
    src: url('./fonts/BasquiatRegular.otf');
}

@font-face {
    font-family: 'Helvetica Bold';
    src: url('./fonts/Helvetica-Font/Helvetica-Bold.ttf');
}

@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700;800&display=swap");



@tailwind base;
@tailwind components;
@tailwind utilities;

