.main {
  width: 30.9%;
  margin-right: 31px;
  flex-direction: column;
  border-radius: 12px;
  /* border: 10px solid #ffffff; */
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(253, 253, 253, 0.816);
  font-family: Helvetica Bold;
}
.sale {
  margin-top: 30px;
  border-radius: 24px;
  color: rgba(255, 255, 255, 0.816);
  margin-bottom: 13px;
  font-family: Helvetica Bold;
}
.main-heading {
  font-size: 35px;
}
.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-radius: 12px;
  font-family: Helvetica Bold;
}
.price-image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
.main-min-head {
  font-size: 20px;
  font-family: Helvetica Bold;
}
.price-text {
  font-size: 25px;
  font-family: Helvetica Bold;
}
.input-box-xp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  font-family: Helvetica Bold;
}
.input-field-text {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.816);
  font-family: Helvetica Bold;
}
.input-sec {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: black;
}
.input-xp {
  font-size: 30px;
  background-color: transparent;
  width: 74px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}
.btn-set {
  background-color: rgb(0, 0, 0);
  padding: 5px 35px;
  color: rgb(255, 255, 255);
  border: 2px solid #a4b480;
  border-radius: 12px;
  font-family: Helvetica Bold;
}
.not-fuckListed{
    background-color: rgb(0, 0, 0);
    padding: 5px 35px;
    color: rgb(255, 255, 255);
    border: 2px solid rgb(151, 3, 3) !important;
    border-radius: 12px;
    font-family: Helvetica Bold;
}
/* .
inner-dev{

} */
.main-p {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #a4b480;
  line-height: 1.6;
}

.main-p-link {
  text-align: center;
  color: #f5cccc;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
  margin-top: 0;
}

.main-p-link a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.816);
}

.main-p-same {
  text-align: center;
  color: rgba(0, 0, 0, 0.816);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 8px;
}

.main-p-simple {
  text-align: center;
  color: rgba(0, 0, 0, 0.816);
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.main-button {
  border-radius: 12px;
  border: 2px solid;
  background-color: rgba(128, 128, 128, 0.538);
  padding: 10px;
  font-weight: bold;
  width: 80%;
  cursor: pointer;
  color: black;
  box-shadow: rgb(250 250 250 / 30%) 0px 6px 0px -2px;
  margin-bottom: 24px;
}

.main-input {
  width: 80%;
  border: 2px solid;
  border-radius: 12px;
  background-color: rgba(128, 128, 128, 0.538);
  padding: 10px 20px;
  font-weight: bold;
  color: black;
  box-shadow: rgb(250 250 250 / 30%) 0px 6px 0px -2px;
  margin-bottom: 24px;
}

.total-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 2px solid rgb(0, 0, 0);
  border-bottom: 2px solid rgb(0, 0, 0);
  margin-top: 40px;
}
.total-text {
  font-size: 25px;
  font-family: Futura Medium;
  color: green;
}
.total-price {
  font-size: 22px;
  font-family: Futura Medium;
  color: green;
}

@media (max-width: 1600px) {
  .sale {
    padding: 3px;
    font-size: 30px;
  }
  .input-box-xp {
  }
  .input-xp {
    font-size: 15px;
  }
  .total-box {
    padding: 5px;
    margin-top: 10px;
  }
}

@media (max-width: 967px) {
    .main {
        width: 70%;
        margin-right: 0px;
      }
  .price-image {
    width: 60px;
    height: 60px;
  }
  .main-min-head {
    font-size: 16px;
    font-family: Helvetica Bold;
  }
  .price-text {
    font-size: 20px;
    font-family: Helvetica Bold;
  }
  .input-xp {
    font-size: 20px;
    width: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .total-text {
    font-size: 20px;
    font-family: Futura Medium;
  }
  .total-price {
    font-size: 18px;
    font-family: Futura Medium;
  }
  .total-box {
    padding: 17px;
  }
}
@media (max-width: 500px) {
    .main {
        width: 97%;
        margin-right: 0px;
      }
}
@media (max-height: 850px) and (max-width: 990px) {
  .main {
    width: 96%;
    margin-right: 0px;
  }
}
@media (max-height: 800px) and (max-width: 990px) {
  .main {
    width: 96%;
    margin-right: 0px;
  }
}
@media (max-height: 600px) and (max-width: 990px) {
  .main {
    width: 96%;
    margin-right: 0px;
  }
}
